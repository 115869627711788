<template>
  <div id="AnnualMeetingList">
    <div class="theme-media">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230831164200686.png" />
    </div>
    <div class="box" v-for="(item, index) in arr" :key="index">
      <!-- 店主报名 -->
      <template v-if="item.signupType == 1">
        <yellowTitle v-if="item.state == 0" info="店主（报名中）"></yellowTitle>
        <yellowTitle v-else-if="item.state == 10" info="店主（审核中）"></yellowTitle>
        <yellowTitle v-else-if="item.state == 20" info="店主（报名成功）"></yellowTitle>
        <yellowTitle v-else-if="item.state == -1" :failed="true" info="店主（报名失败）"></yellowTitle>
      </template>
      <template v-else>
        <yellowTitle v-if="item.state == 0" info="家属（报名中）"></yellowTitle>
        <yellowTitle v-else-if="item.state == 10" info="家属（审核中）"></yellowTitle>
        <yellowTitle v-else-if="item.state == 20" info="家属（报名成功）"></yellowTitle>
        <yellowTitle v-else-if="item.state == -1" :failed="true" info="家属（报名失败）"></yellowTitle>
      </template>
      <!-- <div v-if="item.state == 0" class="shenhe_words">资料已经提交成功，需等待客服审核，如有疑问请联系蜜蛋客服</div> -->
      <div class="line">
        <div class="item_title">姓名：</div>
        <div class="item_content">{{ item.name }}</div>
      </div>
      <div class="line">
        <div class="item_title">性别：</div>
        <div class="item_content">{{ item.sex }}</div>
      </div>
      <div class="line">
        <div class="item_title">民族：</div>
        <div class="item_content">{{ item.minZu }}</div>
      </div>
      <div class="line">
        <div class="item_title">电话：</div>
        <div class="item_content">{{ item.phone || item.agentPhone }}</div>
      </div>

      <div class="line">
        <div class="item_title">身份证号：</div>
        <div class="item_content">{{ item.idCard }}</div>
      </div>
      <!-- <div class="line">
        <div class="item_title">衣服尺码：</div>
        <div class="item_content">{{ item.clothStr || '无服装' }}</div>
      </div> -->
      <div class="line">
        <div class="item_title">授权编码：</div>
        <div class="item_content">{{ item.agentCode }}</div>
      </div>
      <div class="line">
        <div class="item_title">所在地址：</div>
        <div class="item_content">{{ item.addrStr }}</div>
      </div>
      <div class="line">
        <div class="item_content">是否参加新科技，创未来 新品发布会：</div>
      </div>
      <div class="line" v-if="item.isConference === 1">
        <div class="item_content">是（500元定位费）</div>
      </div>
      <div class="line">
        <div class="item_content">是否参加寻梦川西，山野千里旅游：</div>
      </div>
      <div class="line">
        <div class="item_content" v-if="item.isTravel === 1">是（990元定位费）</div>
        <div class="item_content" v-else>否</div>
      </div>
      <div class="line">
        <div class="item_title">支付状态：</div>
        <div class="item_content" v-if="item.state === 0">{{ '待支付(' + item.shouldPrice + '元)' }}</div>
        <div class="item_content" v-else>已支付</div>
      </div>
      <div v-if="item.state == -1" class="line">
        <div class="item_title">失败理由：</div>
        <div class="item_content">{{ item.cancelReasons }}</div>
      </div>
      <div class="order-btns" v-if="item.state === 0">
        <van-button type="primary" color="#E0BD90" round @click="handlePay(item)">继续支付</van-button>
        <van-button type="info" color="#333333" plain round @click="cancleSignUp(item.id)">取消报名</van-button>
      </div>
    </div>
    <div class="operate">
      <!-- <div @click="back" class="continue_btn" v-if="info.isCanSign">继续报名</div> -->
      <div @click="backHome" class="continue_btn">回到首页</div>
    </div>
    <div v-if="currentRow">
      <SelectPayType :showPayLayer="showPayLayer" :payAmount="currentRow.shouldPrice" @close="showPayLayer = false"
        @pay="onAppPay" :isApp="isInApp" />
    </div>
  </div>
</template>

<script>
import yellowTitle from "./components/yellowTitle";
import { wxPay } from "../../../utils/wxPay";
import { deliveryMessage } from "../../../utils/appUtil";
import SelectPayType from "../../../components/Order/SelectPayType";
export default {
  name: "AnnualMeetingList",
  components: { yellowTitle, SelectPayType },
  data() {
    return {
      windowHeight: '',
      arr: [],
      info: {},
      isInApp: '0',
      payType: 0,
      currentRow: null,
      showPayLayer: false
    }
  },
  methods: {
    Init() {
      this.post('/OnlyOnce/AnnualMeeting/GetSignUpNewTravelList', { source: 1 }, true, 2).then(res => {
        this.arr = res.response || []
      });
      this.$toast.loading({ message: '加载中...', duration: 0, mask: true });
      this.post('/OnlyOnce/AnnualMeeting/GetUserDetails', { source: 1 }, true, 2).then(data => {
        this.$toast.clear();
        if (data.code === 1) {
          this.info = data.response;
        }
      }).catch(() => this.$toast.clear())
    },
    back() {
      if (this.arr.length <= 0) return this.$router.push('/Recharge/InformationFill?type=2')
      if (this.arr.find(item => item.signupType == 1).state == -1) {
        this.$router.push('/Recharge/InformationFill?type=1&inApp=' + this.isInApp)
      } else {
        this.$router.push('/Recharge/InformationFill?type=2&inApp=' + this.isInApp)
      }
    },
    backHome() {
      if (this.isInApp === '1') deliveryMessage({ 'action': 'goHome' })
      else this.$router.push("/")
    },
    //点击支付
    handlePay(item) {
      if (this.isInApp === '1') {
        this.currentRow = item;
        this.showPayLayer = true;
      } else {
        this.getPayInfo(item.id);
      }
    },
    //微信支付
    getPayInfo(orderId) {
      this.$toast.loading({ duration: 0, message: '支付中...', overlay: true });
      this.get('/OnlyOnce/AnnualMeeting/PayNewTravelOrder', {
        orderId, payType: 1, sourceType: 2
      }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let data = res.response.wechat;
          wxPay({
            appId: data.appid, timeStamp: data.timeStamp, nonceStr: data.nonceStr,
            paySign: data.sign, signType: data.signType, package: data.package
          }).then(() => {
            this.$toast("支付成功");
            window.location.reload();
          }).catch(msg => {
            this.$dialog.alert({
              title: '提示',
              message: msg
            }).then(res => { })
          })
        }
      }).catch(() => this.$toast.clear())
    },
    //取消订单
    cancleSignUp(signupId) {
      this.$dialog.confirm({
        title: "提示",
        message: "是否确认取消报名?",
        confirmButtonText: "取消报名",
        confirmButtonColor: "#E0BD90"
      }).then(() => {
        this.get('/OnlyOnce/AnnualMeeting/CancelSignUpNewTravel', { signupId, reasons: "" }, 2).then(res => {
          if (res.code === 1) {
            this.$dialog.confirm({
              title: "提示",
              message: "报名取消成功",
              confirmButtonText: "重新报名",
              confirmButtonColor: "#E0BD90",
              cancelButtonText: "首页"
            }).then(() => {
              this.$router.push("/signUp?inapp=" + this.isInApp)
            }).catch(() => {
              if (this.isInApp === '1') deliveryMessage({ 'action': 'goHome' })
              else this.$router.push("/")
            })
          }
        })
      }).catch(() => { })
    },
    //app 支付
    onAppPay(payType) {
      this.showPayLayer = false;
      let realPayType = payType === '1' ? 2 : 1;
      let message = { action: "jumpNewTravelPay", data: { orderId: this.currentRow.id, payType: realPayType } };
      deliveryMessage(message);
    }
  },
  created() {

    let { inApp, replace } = this.$route.query;
    let _href = window.location.href;
    if (replace !== '1') {
      window.location.replace(_href + `&replace=1`);
    }
    if (inApp === '1') this.isInApp = inApp;
    this.windowHeight = window.innerHeight + 'px'
    this.Init()
  },
  mounted() {
    window.vm = this;
    window.newTravelOrderSuccess = () => {
      console.log(111);
      window.vm.Init();
      // window.location.reload();
    }
    window.scroll({ top: 0 });
    window.orderSuccess = () => {
      window.location.reload();
    }
    window.clearPrompt = () => {
      this.showPayLayer = false;
    }
  }
}
</script>

<style lang="scss" scoped>
#AnnualMeetingList {
  width: 100%;
  padding-bottom: 30px;
  min-height: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100%;
  font-size: 0;
  background-position: 0px -3px;
  overflow: hidden;

  .theme-media {
    img {
      width: 100%;
    }
  }



  .box {
    background-color: #fff;
    margin-left: 20px;
    margin-right: 20px;
    box-sizing: border-box;
    padding: 30px 18px 18px 18px;
    border: 4px solid #E0BD90;
    border-radius: 9px;
    margin-top: 30px;
    position: relative;
    font-size: 14px;

    .shenhe_words {
      color: #FF4E4E;
      margin-bottom: 10px;
      margin-left: 25px;
      margin-right: 25px;
    }

    .line {
      display: flex;
      margin-bottom: 10px;
      font-size: 14px;

      .item_title {
        color: #333;
        min-width: 80px;
        text-align: start;
      }

      .item_content {
        text-align: start;
        word-break: break-all;
      }
    }

    .order-btns {
      padding-top: 15px;

      .van-button {
        width: 51%;
        height: 34px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .van-button--info {
        border: none;
      }
    }
  }

  .operate {
    display: flex;
    padding: 50px 15px 11px;
    align-items: center;

    .continue_btn {
      height: 34px;
      flex: 1;
      background-size: 100%;
      background-repeat: no-repeat;
      color: #fff;
      font-size: 14px;
      line-height: 34px;
      margin-top: 10px;
      border-radius: 34px;
      margin-right: 20px;
      background-color: #209DFF;

      &:last-child {
        color: #E0BD90;
        margin-right: 0;
        background-color: #fff;
      }
    }
  }
}
</style>